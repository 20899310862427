import MainContent from "../../components/home";

const Home = () => {
  return (
    <>
      <MainContent />
    </>
  );
};

export default Home;
