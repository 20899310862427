import React, { useState, useEffect, useRef } from "react";
import {
  AbsoluteImage,
  AnswerContainer,
  AppCorner,
  BackCenter,
  BodyInformation,
  BodyWrapper,
  BoxArea,
  BoxContainer,
  BoxImage,
  BoxText,
  BusinessScale,
  BusinessTool,
  ButtonArea,
  ButtonPress,
  ButtonPressBlacck,
  CenterMage,
  CommentAreaDiv,
  CommentBox,
  Commentor,
  ContainerHolderDiv,
  DisplayArea,
  DivScroll,
  Footer,
  FooterContent,
  Frequently,
  Header,
  ImageCollab,
  ImageHolder,
  LeftMage,
  LogoArea,
  LogoDiv,
  LongBox,
  MageStory,
  MainWrapper,
  MerchroEnglish,
  MerchroNavsArea,
  NavArea,
  Navigator,
  Questionaira,
  Questions,
  QuestionsAre,
  RightMage,
  ScrollEffect,
  ShortBox,
  ShortTextInBox,
  SlantDiv,
  TalesHandler,
  TalesStory,
  TextArea,
  TextDiv,
  TextInBox,
  ToolInformation,
  UseFulArea,
  ViewImage,
} from "./style";
import {
  AiOutlineRight,
  AiOutlineLeft,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import {
  Bounce,
  Fade,
  Zoom,
  Reveal,
} from "react-awesome-reveal";
import { Star } from "../../assets/star";
import { FaTwitter , FaInstagram } from "react-icons/fa";

interface Question {
  id: number;
  text: string;
  answer: string;
}

const MainContent = () => {
  const [toggledQuestion, setToggledQuestion] = useState<number | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % numberOfSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + numberOfSlides) % numberOfSlides
    );
  };

  const numberOfSlides = 2;

  const handleToggle = (questionId: number) => {
    setToggledQuestion((prev) => (prev === questionId ? null : questionId));
  };

  const questions: Question[] = [
    {
      id: 1,
      text: "How does Mage know the right plan for me?",
      answer:
        "We help you select the right type of plan based on your income, lifestyle, and future goals. Our team of experts is always available to guide you through the process.",
    },
    {
      id: 2,
      text: "What does an health plan cover?",
      answer:
        "All plans you buy through Mage cover 10 health benefit categories. This includes preventive care, emergency services, and prescription drugs.",
    },
    {
      id: 3,
      text: "What is a premium?",
      answer:
        "A premium is a monthly payment to your provider for health insurance. Just like rent or a streaming service, you keep your plan active by paying for it each month.",
    },
    {
      id: 4,
      text: "How much does it cost to use Mage?",
      answer:
        "Mage charges a one time fee to help set up your plan. After that, you only pay for the plan you choose. We don’t charge any hidden fees.",
    },
  ];

  const businessScaleRef = useRef<HTMLDivElement>(null);

  const handleScrollToBusinessScale = () => {
    if (businessScaleRef.current) {
      businessScaleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const advantage = [
    {
      image: "/banknotes.png",
      topic: "Enter your info.",
      desc:
        "Share what's most important to you, like how you plan to use your health coverage.",
    },
    {
      image: "/circle-stack.png",
      topic: "Review recommended plans.",
      desc:
        "We'll show you the plans that work best for your needs, goals, and budget.",
    },
    {
      image: "/arrows-right-left.png",
      topic: "Get coverage (and peace of mind).",
      desc:
        "Once you've chosen a plan, we'll help you apply and enroll.",
    },
  ];

  const handleButtonClick = () => {
    window.open("https://forms.gle/UBgJqKVJFYRfsbgN6", "_blank");
  };

  // const handleAppStore = () => {
  //   window.open("https://apps.apple.com/us/app/merchro/id6448023194", "_blank");
  // };

  // const handlePlayStore = () => {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.merchro.creators.app&pli=1",
  //     "_blank"
  //   );
  // };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const originalContent = container.children[0];

    const cloneContent = () => {
      const clone = originalContent?.cloneNode(true);
      container?.appendChild(clone);
    };

    const handleAnimationIteration = () => {
      const firstChild = container?.firstChild;
      if (firstChild) {
        container?.removeChild(firstChild);
      }
      cloneContent();
    };
    cloneContent();

    container?.addEventListener("animationiteration", handleAnimationIteration);
    return () => {
      container?.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    };
  }, []);

  const ContainerHolder: React.FC<{ category: string }> = ({ category }) => (
    <ContainerHolderDiv>
      <Star />
      <p>{category}</p>
    </ContainerHolderDiv>
  );
  const categories = [
    "Freelancers",
    "Designers",
    "Artistes",
    "Photographers",
    "Writers",
    "Therapists",
    "Solopreneurs",
  ];
  const duplicationCount = 1000;
  const duplicatedContent = Array.from(
    { length: duplicationCount },
    (_, index) => (
      <ContainerHolder
        key={index}
        category={categories[index % categories.length]}
      />
    )
  );

  return (
    <>
      <MainWrapper>
        <Header ref={businessScaleRef}>
          <Zoom triggerOnce>
            <LogoDiv>
              <img src="/logosvg.svg" alt="logo" />
            </LogoDiv>
          </Zoom>

          <NavArea></NavArea>
          {/* <ButtonArea>
            <p onClick={handleButtonClick}>Login</p>
            <img
              src="/iconapp.png"
              alt="plystore"
              onClick={handleAppStore}
              width="25px"
            />
            <img
              src="/iconplay.png"
              alt="plystore"
              onClick={handlePlayStore}
              width="20px"
            />
          </ButtonArea> */}
          {/* <HamburgerDiv>
            <AiOutlineMenu color="white" size={20} />
          </HamburgerDiv> */}
        </Header>

        <BodyWrapper>
          <BackCenter>
            <ButtonPressBlacck>For Independent Workers</ButtonPressBlacck>
            <TextArea style={{ width: "auto" }}>
              <>
                <h3>Your safety net, 
                made easy.</h3>
              </>
              <>
                <p>
                Mage is the easiest way to set up employee benefits 
                  <br /> like HMO, Pension, and Early Pay.
                </p>
              </>

              <ButtonPress onClick={handleButtonClick}>Schedule a call</ButtonPress>
            </TextArea>
          </BackCenter>
          <ViewImage>
            <img src="/people.png" alt="landing" />
          </ViewImage>
        </BodyWrapper>

        <BodyInformation>
          <ImageCollab>
            <img src="/groups5.png" alt="collab" />
          </ImageCollab>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextArea width="65%" bottom="70px">
              <>
                <h2
                  style={{
                    color: "#222",
                  }}
                >
                  We do the research,
                  <br />
                    you get the benefits.
                </h2>
                {/* 
                 <p style={{ color: "#222" }}>
                  We believe credit is the key to unlocking opportunities and achieving the life you aspire to, free from 
                  prohibitive rates and rejections.
                </p> 
                */}
              </>
            </TextArea>
          </div>

          <BoxArea>
            <LongBox>
              <BoxContainer>
                <AbsoluteImage
                  mobile="none"
                  top="auto"
                  width="200px"
                  size="40px"
                  left="43%"
                  image="20px"
                  style={{
                    marginTop: "140px",
                  }}
                >
                  <span>
                    <Zoom triggerOnce>
                      <img
                        src="/card.png"
                        alt="money"
                        style={{
                          width: "150px",
                          marginLeft: 50
                        }}
                      />
                    </Zoom>
                  </span>
                </AbsoluteImage>
                <BoxText>
                  <TextInBox>
                    <p style={{ width: "100%" }}>
                    Affordable Health Coverage 
                      <br />
                      That Cares for You
                    </p>
                    <span style={{ width: "70%" }}>
                      Enjoy peace of mind with comprehensive health plans designed for you.
                    </span>
                  </TextInBox>
                </BoxText>
                <BoxImage
                  top="120px"
                  style={{
                    width: "auto",
                    height: "100%",
                    marginRight: "20px",
                  }}
                >
                  <img src="/image4.png" alt="pic" />
                </BoxImage>
              </BoxContainer>
            </LongBox>
            <ShortBox>
              <ShortTextInBox>
                <div>
                  <img src="/split.png" alt="logo" />
                </div>
                <p>Plan for Tomorrow, Live Your Best Life Today</p>
                <span>
                We help you set aside a portion of your earnings in a secured pension plan.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <ShortBox>
              <ShortTextInBox>
                <div>
                  <img src="/dollar.png" alt="logo" />
                </div>
                <p> Need Finacial support,
                  we're one call away </p>
                <span>
                  When you join Mage, you get a dedicated concierge ready to simplify your financial journey.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <LongBox>
              <div
                style={{
                  position: "absolute",
                }}
              ></div>
              <BoxContainer>
                <BoxText>
                  <TextInBox>
                    <p> No more payment delays from clients and platforms</p>
                    <span style={{ width: "70%" }}>
                      Experiencing delay with your payments. Get access to part of your earnings today with early pay.
                    </span>
                  </TextInBox>
                </BoxText>
                <BoxImage
                  style={{
                    width: "auto",
                    height: "65%",
                    marginRight: "-30px",
                    marginTop: "10px",
                  }}
                >
                  <img src="/frame.svg" alt="pic" />
                </BoxImage>
              </BoxContainer>
            </LongBox>
          </BoxArea>
        </BodyInformation>

        <>
          <SlantDiv ref={containerRef}>
            <div>{duplicatedContent}</div>
          </SlantDiv>
        </>

        <BusinessScale>
          <ButtonPressBlacck
            style={{
              backgroundColor: "#2B892E",
              color: "white",
            }}
          >
           How it works
          </ButtonPressBlacck>
          <TextArea width="70%" bottom="50px">
            <Zoom triggerOnce>
              <h2>
              Get covered fast,
               <br />
               and on your schedule
              </h2>
            </Zoom>
          </TextArea>
          {/* 
          <ImageHolder>
            <img src="/image.png" alt="landing" />
          </ImageHolder>
          */}
          <BusinessTool>
            {advantage.map((info) => (
              <ToolInformation>
                <div>
                  <div>
                    <Reveal>
                      <img src={info.image} alt="stack" />
                    </Reveal>
                  </div>
                  <p>{info.topic}</p>
                </div>
                <Fade>
                  <span>{info.desc}</span>
                </Fade>
              </ToolInformation>
            ))}
          </BusinessTool>
        </BusinessScale>

        <TalesHandler>
          <TalesStory>
            <>
              <h3>Tales from the Mage Guild</h3>
            </>
          </TalesStory>
          <MageStory>
            <LeftMage></LeftMage>
            <CenterMage>
              <ScrollEffect>
                <DivScroll
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <>
                    <>
                      <CommentBox className="comment-box">
                        <CommentAreaDiv>
                          <div>
                            <Navigator>
                              <AiOutlineLeft onClick={handlePrevSlide} />
                            </Navigator>
                            <TextDiv>
                              <p>
                              Team is fantastic and responsive, 
                              feel great knowing that my future is in great hands. I'm a fan.
                              </p>
                            </TextDiv>
                            <Navigator>
                              <AiOutlineRight onClick={handleNextSlide} />
                            </Navigator>
                          </div>
                        </CommentAreaDiv>
                        <Commentor>
                          <div>
                            <img src="/andy.jpg" alt="user" />
                            <div>
                              <p>Andy</p>
                              <span>Music Artiste</span>
                            </div>
                          </div>
                        </Commentor>
                      </CommentBox>
                    </>
                  </>
                </DivScroll>

                <DivScroll
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <>
                    <>
                      <CommentBox className="comment-box">
                        <CommentAreaDiv>
                          <div>
                            <Navigator>
                              <AiOutlineLeft onClick={handlePrevSlide} />
                            </Navigator>
                            <TextDiv>
                              <p>
                                “I quit my full time job to focus on freelancing
                                6 months ago. With Mage, I can keep my HMO
                                and Pension without the admin work. Great stuff”
                              </p>
                            </TextDiv>
                            <Navigator>
                              <AiOutlineRight onClick={handleNextSlide} />
                            </Navigator>
                          </div>
                        </CommentAreaDiv>
                        <Commentor>
                          <div>
                            <img src="/carl.png" alt="user" />
                            <div>
                              <p>Carl Rowan</p>
                              <span>Designer</span>
                            </div>
                          </div>
                        </Commentor>
                      </CommentBox>
                    </>
                  </>
                </DivScroll>

                <DivScroll
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <>
                    <>
                      <CommentBox className="comment-box">
                        <CommentAreaDiv>
                          <div>
                            <Navigator>
                              <AiOutlineLeft onClick={handlePrevSlide} />
                            </Navigator>
                            <TextDiv>
                              <p>
                                I quit my full time job to focus on freelancing
                                6 months ago. With Mage, I can keep my HMO
                                and Pension without the admin work. Great stuff.
                              </p>
                            </TextDiv>
                            <Navigator>
                              <AiOutlineRight onClick={handleNextSlide} />
                            </Navigator>
                          </div>
                        </CommentAreaDiv>
                        <Commentor>
                          <div>
                            <img src="/ali.jpeg" alt="user" />
                            <div>
                              <p>Femi Ali</p>
                              <span>Developer</span>
                            </div>
                          </div>
                        </Commentor>
                      </CommentBox>
                    </>
                  </>
                </DivScroll>
              </ScrollEffect>
            </CenterMage>
            <RightMage></RightMage>
          </MageStory>
        </TalesHandler>

        <Questionaira>
          <Frequently>
            <div>
              <>
                <h3>You might want to know</h3>
              </>
            </div>
          </Frequently>
          <QuestionsAre>
            {questions.map((question) => (
              <Questions key={question.id}>
                <div onClick={() => handleToggle(question.id)}>
                  <>
                    <p>{question.text}</p>
                  </>
                  <span onClick={() => handleToggle(question.id)}>
                    {toggledQuestion === question.id ? (
                      <>
                        <AiOutlineUp
                          onClick={() => handleToggle(question.id)}
                          color="white"
                          size={20}
                          strokeWidth={6}
                        />
                      </>
                    ) : (
                      <>
                        <AiOutlineDown
                          onClick={() => handleToggle(question.id)}
                          color="white"
                          size={20}
                        />
                      </>
                    )}
                  </span>
                </div>
                {toggledQuestion === question.id && (
                  <>
                    <AnswerContainer
                      isVisible={toggledQuestion === question.id}
                    >
                      <span>
                        <>{question.answer}</>
                      </span>
                    </AnswerContainer>
                  </>
                )}
              </Questions>
            ))}
          </QuestionsAre>
        </Questionaira>

        <AppCorner>
          <DisplayArea>
            <div>
              <AbsoluteImage
                mobile="none"
                top="0"
                width="0"
                size="0"
                left="-9%"
                image="20px"
              >
                <span>
                  <Bounce triggerOnce>
                    <img src="/thunder.png" alt="money" />
                  </Bounce>
                </span>
              </AbsoluteImage>
              <AbsoluteImage
                mobile="none"
                top="0"
                width="0"
                size="0"
                left="77%"
                style={{
                  marginTop: "80px",
                }}
              >
                <span>
                  <>
                    <img src="/money.png" alt="money" />
                  </>
                </span>
              </AbsoluteImage>
              <h3>
                Your safety net,
                <br />
                made easy
              </h3>
              <div style={{ cursor: "pointer" }}>
               
                <ButtonPress
                  style={{
                    backgroundColor: "#F2F2F2",
                    color: "#6941C6",
                  }}
                  onClick={handleButtonClick}
                >
                  Schedule a Call
                </ButtonPress>
              </div>
            </div>
          </DisplayArea>
        </AppCorner>

        <Footer>
          <FooterContent>
            <MerchroNavsArea>
              <LogoArea>
                <div>
                  <i>
                    <>
                      <img src="/logofooter.png" alt="logo" onClick={handleScrollToBusinessScale} />
                    </>
                  </i>
                  <div>
                    <i>
                      <>
                        <p>Employee Benefits for Independent Workers</p>
                      </>
                    </i>
                  </div>
                </div>
              </LogoArea>
              <UseFulArea>
                <div>
                  <>
                    <p>Contact us</p>
                  </>
                  <>
                    <span>hello@trymage.com</span>
                  </>
                  <>
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      +1 22 5239 2278
                    </span>
                  </>
                  <>
                    <div>
                    <a href="https://twitter.com/try_mage" target="_blank" rel="noreferrer"><FaTwitter color="#fff" size={25} /></a>
                    <a href="https://instagram.com/try_mage" target="_blank" rel="noreferrer"><FaInstagram color="#fff" size={25} /></a>
                    </div>
                  </>
                </div>
               
              </UseFulArea>
            </MerchroNavsArea>
            <MerchroEnglish>
              <div>
                <>
                  <span>&copy; Merchro Inc</span>
                </>
              </div>
              <div>
                <>
                  <span></span>{" "}
                </>
              </div>
            </MerchroEnglish>
          </FooterContent>
        </Footer>
      </MainWrapper>
    </>
  );
};

export default MainContent;
